/* Query.css */
.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 150px 0;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .inputName {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .textArea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 100px;
    box-sizing: border-box;
  }
  
  .button {
    background-color: #ffd301;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

  @media (max-width : 600px) {
    .container{
      width: 100%;
      padding: 100px 10px;
      margin: 0 10px;
    }
  }