.packagesnextsection{
    display: flex;
    align-items: center;
    justify-content: center;
}

.packagesnextinnerdiv{
    width: 60%;
    display: grid;
    row-gap: 10px;
}

.packagesnextdescription{
    background-color: #ccc;
    padding: 20px;
}

.packagesnextdescription p{
    margin-bottom: 10px;
}

.packagesnextquote{
    width: 60%;
    border-left: 3px solid #ccc;
    padding-left: 30px;
}

.packagesnextbtn{
    padding: 10px 15px;
    /* background-color: #3389d7; */
    color: white;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 20px;
}

.packagesnextimagediv{
    width: 100%;
    height: 350px;  
}

.packagesnextimg{
    height: 350px;
    width: 100%;
}