.adideassection{
    padding: 50px 0;
    background-image: url(../assets/home-intro-bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
}

.adideassection::before{
    content: "";
    background-image: url(../assets/slider-mask.png);
    /* background-color: #ffffff; */
    width: 100%;
    height: 130px;
    bottom: 30px;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
}

.adideah3{
    font-family: "Mansalva", cursive;
    color: #f29727;
    margin-top: 30px;
}

.adideah1{
    line-height: 1;
}

.adidearow1{
    padding-top: 50px;
    width: 85%;
    /* display: flex; */
}

.adideacolumn1{
    margin: 0 25px;
}

.adiimagediv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.adiimagediv img{
    width: 80%;
    border-radius: 50%;
    height: 300px;
}

.adideaparadiv{
    padding-top: 20px;
    text-align: center;
}

.adideaparadiv h3{
    font-weight: 700;
    font-size: 20px;
}

.adideaparadiv h3:hover{
    color: #f29727;
}

.heritagename{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.heritagedescription{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.adideabtndiv{
    display: grid;
    place-items: center;
    margin-top: 20px;
}

.adideabtndiv button{
    padding: 10px 20px;
    background-color: black;
    color: white;
}

@media screen and (max-width:600px) {
    .adideassection::before{
        bottom: -10px;
    }

    .adidearow1{
        flex-direction: column;
    }

    .adiimagediv img{
        width: 100%;
        height: 250px;
    }
}