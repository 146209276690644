/* Customize the label (the container) */
.sort-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 850px; /* Adjust the height as needed */
    width: 220px; /* Adjust the width as needed */
    border: 1px solid #3333335c; /* Border color */
    /* border-radius: 8px; Border radius for rounded corners */
    margin-left: 50px;
    padding: 1vw;
    line-height: 120%;
    margin-top: 50px;
  }

  .sort-box-container h2{
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 10%;
  }
  
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 100%;
  }
  
  /* Hide the browser's default checkbox */
  .sortboxinput{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #393737;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media (max-width : 600px) {
    .sort-box-container{
      display: none;
    }
  }
  