.cabcontactsection{
    display: grid;
    place-items: center;
    padding-top: 100px;
}

.cabcontactrow{
    display: flex;
    width: 80%;
}

.cabcontactcolumn1, .cabcontactcolumn2{
    width: 50%;
}

.cabcontactcolumn2{
    background-color: #eee;
    padding: 30px;
}

.cabheading{
    font-size: 3rem;
    font-weight: 700;
}

.cabspan{
    color: #ff7c08;
}

.cabinputdiv{
    display: flex;
    width: 100%;
    margin-top: 30px;
}

.cabinput{
    width: 50%;
    padding: 15px;
}

.cabbutton{
    padding: 15px;
    text-transform: uppercase;
    background-color: #ff7c08;
    width: 100%;
    font-weight: 600;
    color: white;
}

@media screen and (max-width:600px) {
    .cabcontactrow{
        flex-direction: column-reverse;
        width: 95%;
    }

    .cabcontactcolumn1, .cabcontactcolumn2{
        width: 100%;
    }

    .cabcontactcolumn2{
        padding: 10px;
    }
}