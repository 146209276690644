.homeee{
    width: 100%;
    height:100%;
    overflow-x: hidden;
    margin-top: 100px;
}

.imageslider{
    height: 70vh;
    overflow: hidden;
    width: 100%;
}

.sliderimage{
    width:100%;
    height:70vh;
    overflow:hidden;
    /* animation: fadeInOut 3s linear infinite */
}

@keyframes fadeInOut {
    0%{
        transform: scale(0.95);
      }
      15%{
        transform: scale(1);
      }
      85%{
        transform: scale(1);
      }
      100%{
        transform: scale(0.95);
      }
}


@media screen and (max-width: 600px) {
    .homeee{
        display: block;
        margin-bottom: 0px;
        overflow-x: hidden !important; 
    }
    .homeimage{
        width:100%;
        height: 35vh;
    }

    .imageslider{
        height: 50vh;
    }

    .sliderimage{
        height: 50vh;
    }
  }

  