.label {
  background-color: rgb(222, 142, 37);
  height: 11vh;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
}

@media (max-width: 600px) {
  .label {
    background-color: rgb(222, 142, 37);
    height: 14vh;
    font-size: 12px;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    line-height: 3vh;
  }
}
