.shoppingsection{
    margin: 6vmax auto;
}

.productsHeading {
    margin: 2vmax auto;
    width: 15vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.171);
    padding: 2vmax;
    color: rgba(0, 0, 0, 0.678);
    font: 500 1.5vmax "Roboto";
    text-align: center;
}

.productrow{
   display: flex;
   margin-top: 30px;
}

.productcolumn1{
    width: 20vw;
}

.prductcolumn2{
    display: flex;
    width: 80vw;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
}

@media screen  and (max-width:600px) {
    .shoppingsection{
        padding-top: 15vmax ;
        margin-top: 0;
    }
   
}