.prasadamscreendiv{
    background-image: url(../assets/prasadam-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    position: relative;
    color: #fff;
    /* padding-top: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.prasadamscreendiv::after{
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--colorBlack);
  opacity: 0.5;
  z-index: -1;
}

.prasadamscreenrow{
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.prasadamscreencolumn1{
  width: 50%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prasadamscreenh1 {
  font-size: 58px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--colorWhite);
  margin-bottom: 20px;
}

.prasadamscreenh3{
  color: var(--colorPrimary) !important;
  text-transform: capitalize;
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  font-family: var(--cursiveFont);
  
}

.banner_text p {
  font-size: 18px;
  margin: 15px 0px 28px 0px;
  color: var(--colorWhite);
}

.banner_text form {
  background: var(--colorWhite);
  padding: 10px;
  border-radius: 80px;
  position: relative;
  color: black;
}

.banner_text form input {
  border-radius: 30px;
  border: 1px solid #ccc;
  border-color: #ccc;
  padding: 15px 25px;
  width: 100%;
  
}

form .commonbtn {
  position: absolute;
  top: 15px;
  right: 20px;
  background: var(--colorPrimary);
  text-transform: capitalize;
  color: var(--colorWhite);
  padding: 12px 40px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 600;
}

.img-fluid{
  border-radius: 50%;
} 

.prasadamscreenimagediv{
    position: relative;
    border-radius: 50%;
    padding: 19px;
    box-shadow: var(--boxShadow);
    z-index: 1;
    /* right: 100px; */
    /* bottom: 100px; */
}

.prasadamscreenimagediv::after{
    position: absolute;
    content: "";
    background: url(../assets/banner_img_shapes.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: rotate linear 100s infinite;
    z-index: -1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-animation: rotate linear 100s infinite;
    
}

@keyframes rotate{
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}

@media screen and (max-width:600px) {
  .prasadamscreendiv{
    padding-top: 100px;
    align-items: flex-start;
  }
  .prasadamscreenrow{
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
  }
  .prasadamscreencolumn1{
    width: 100%;
    padding: 0;
  }

  .prasadamscreenimagediv{
    padding: 10px;
  }

  .prasadamscreenimagediv::after{
    display: none;
  }

  .img-fluid{
    border-radius: 50%;
    height: 300px;
    width: 300px;
  } 

  .prasadamscreenh1{
    font-size: 40px;
  }

  .prasadamscreenh3{
    font-size: 20px;
  }
}