.searchBox {
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: stretch;
    background-color: tomato;
  }
  
  .searchBox > input[type="text"] {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.274);
    background-color: white;
    border: none;
    color: rgba(0, 0, 0, 0.637);
    padding: 1vmax 2vmax;
    width: 100%;
    outline: none;
    border-radius: 0%;
    font: 300 1.1vmax cursive;
    box-sizing: border-box;
    height: 100%;
  }
  
  .searchBox > input[type="submit"] {
    height: 100%;
    border-radius: 0%;
    background-color: #ff7c08;
    border: none;
    padding: 1vmax;
    width: 20%;
    font: 300 1.1vmax "Roboto";
    cursor: pointer;
    color: white;
    transition: all 0.5s;
  }
  
  .searchBox > input[type="submit"]:hover {
    background-color: rgb(55, 97, 214);
  }
  
  @media screen and (max-width: 600px) {
    .searchBox > input[type="text"] {
      width: 100%;
      font: 300 4vw cursive;
      height: 10%;
    }
  
    .searchBox > input[type="submit"] {
      height: 10%;
      width: 30%;
      font: 300 4vw "Roboto";
    }
  }