.booknowection{
    padding: 30px;
    width: 50vh;
    height: auto;
    border-radius: 10px;
    background-color: #eee;
    backdrop-filter: blur(0.8);
    opacity: 1;
    z-index: 999;
    overflow: auto;
    margin: auto;
    margin-top: 100px;
}

.booknowsection{
    padding: 30px;
    width: 50vh;
    height: auto;
    border-radius: 10px;
    background-color: #eee;
    backdrop-filter: blur(0.8);
    opacity: 1;
    z-index: 999;
    overflow: auto;
    margin: auto;
    margin-top: 100px;
}


.booknowheading{
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    color: black;
}

.booknowinput{
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid black;
    margin-bottom: 20px;
    color: black;
}

.booknowlabel{
    color: black;
    font-weight: 600;
}

.booknowinputbtn{
    width: 100%;
    padding: 15px;
    font-weight: 600;
    background-color: black !important; 
    color: white;
}

@media screen and (max-width:600px) {
    .booknowsection{
        margin-top: 0;
        padding-top: 140px;
    }
}