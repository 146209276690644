.model{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 999;
    overflow: scroll;
}


.model.open{
    background-image: url("../assets/guesthousebooking.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition:opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;

}

.model.open::after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 135vh;
    bottom: 0;
    background-color: var(--colorBlack);
    opacity: 0.7;
    z-index: -1;
}

.modelcloseButton{
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 5px;
    font-size: 35px;
    background: transparent;
    color: white;
    cursor: pointer;
    z-index: 999;
}

@media screen and (max-width:600px) {
    .modelcloseButton{
        position: absolute;
        right: 10px;
        top: 10px;
        color: white;
    }

    .model.open{
        height: 100vh;
    }
}