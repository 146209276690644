.footerabovediv{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    position: relative;
    bottom: -50px;

}

.footeraboverow{
    background-image: url(../assets/pattern_bg_3.png);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-repeat: repeat;
    background-size: auto;
    background-color: #1B1B1B;
    width: 80%;
}

.footerabovecolumn1, .footerabovecolumn2, .footerabovecolumn3{
    padding: 35px 0px;
    color: white;
    display: flex;
}

.footerabovecolumn2{
    /* background-color: #ff7c08; */
    clip-path: polygon(calc(100% - 25px) 0%, 100% 50%, calc(100% - 25px) 100%, 0% 100%, 25px 50%, 0% 0%);
    color: white;
    font-weight: 600;
}

.info-card_icon{
    width: 30%;
    display: grid;
    place-items: end;
}

.info-card_content{
    width: 70%;
}

.info-card_icon i{
    background-color: white;
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    color: black
}

@media screen and (max-width:600px) {
    .footerabovediv{
        bottom: -100px;
        z-index: 1;
    }
    .footerabovecolumn1, .footerabovecolumn2, .footerabovecolumn3{
        grid-column: span 3;
    }
    
}