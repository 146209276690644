.box {
  display: flex;
  gap: 7px;
  /* position: relative; */
  width: 70vw;
  margin-top: 40px;
  padding-bottom: 20px; /* Add some space between the cards */
  margin-bottom: -90px;
}

.product-container {
  height: 60vh;
  width: 40%;
  display: flex;
  gap: 6px; /* Adjust the gap based on your preference */
  margin-right: 7px;
  margin-left: 20px;
}

.main-slider {
  height: 80%;
  width: 100%;
  line-height: 0;
}

.main-slider img {
  height: 80%;
  width: 100%;
  vertical-align: top;
}

.strip-slider {
  height: 100%;
  width: 25%;
}

.strip-slider img {
  height: 55%;
  width: 60%;
}

.details-container {
  height: 100%;
  width: 60%;
  flex-grow: 10;
  max-width: 500px;
  /* margin-left: -110px; */
  margin-bottom: 90px;
  padding: 1px;
  padding-left: 50px;
  box-sizing: border-box;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: -100px;
}

.description {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 1px;
}

.description h2 {
  font-size: 26px;
  font-weight: 600;
}

.view2 {
  display: none;
}

.distance {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.distance p {
  font-size: 3vh;
  font-weight: 500;
}

.rating {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rating img {
  height: 40px;
  margin-top: -10px;
}

.rating span {
  font-size: 20px;
}

.feature {
  display: flex;
  gap: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ac {
  width: 40px;
}

.recliner {
  width: 40px;
}

.wifi {
  width: 30px;
}

.price-desc {
  display: flex;
  flex-direction: column;
}

.price {
  display: flex;
  gap: 16px;
  align-items: baseline;
}

.rate {
  color: rgba(128, 128, 128, 0.774);
}

.disratre {
  font-size: 25px;
  font-weight: 650;
  color: #ff7c08;
}

.discount {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: rgb(234, 218, 35);
  /* margin-bottom: -90px; */
}

.desc {
  margin-top: -6%;
}

.button-2 {
  /* background-color: #ff7c08; */
  display: none;
}

@media (min-width: 768px) {
  .button-2 {
    display: block;
    /* background-color: #ff7c08; */
    /* hover: #9b2c2c; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: #ffffff;
    border-radius: 0.375rem;
    margin-top: 0;
    margin-bottom: 3rem;
  }
}

/* product.css */
.line-after-card {
  width: 850px;
  border-bottom: 1px solid #ccc; /* Adjust color and style as needed */
  margin-bottom: 20px; /* Adjust margin as needed */
  margin-left: 40px;
}

/* PriceRangeSlider.css */

.line-break {
  margin-top: 18%;
  /* height: 5px; */
  border-bottom: 1px solid #ccc;
}

.price-range-container {
  margin-left: 50px;
  width: 230px;
  text-align: center;
}

input[type="range"] {
  width: 100%;
  margin-bottom: 10px;
}

#priceValue {
  font-size: 18px;
}

@media (max-width: 600px) {
  .box {
    display: flex;
    flex-direction: column;
    gap: 10%;
  }
  .line-break {
    display: none;
  }
  .price-range-container {
    display: none;
  }
  .product-container {
    height: 60vh;
    width: 100vw;
    display: flex;
    gap: 6px; /* Adjust the gap based on your preference */
    margin-left: 0px;
    padding: 4%;
  }
  .strip-slider {
    display: none;
  }
  .main-slider {
    width: 100%;
    height: 100%;
  }

  .details-container {
    height: 40vh;
    width: 100vw;
    padding: 4%;
    box-sizing: border-box;
    flex-direction: column;
    margin-left: 2%;
    margin-top: -30%;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 16%;
    margin-bottom: 1px;
  }

  .description h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .view2 {
    margin-top: -2%;
    display: flex;
    gap: 3%;
  }
  .lorem {
    display: none;
  }
  .distance p {
    font-size: 2vh;
    font-weight: 500;
  }
  .distance img {
    width: 4%;
  }
  #scrn {
    display: none;
  }

  .rating {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .rating img {
    height: 40px;
    margin-top: -10px;
  }

  .rating span {
    font-size: 14px;
  }

  .feature {
    display: flex;
    gap: 20px;
    font-weight: 600;
    margin-bottom: -1%;
  }

  .ac {
    width: 30px;
  }
  .tv {
    width: 30px;
  }
  .recliner {
    width: 35px;
  }

  .wifi {
    width: 26px;
  }

  .price-desc {
    display: flex;
    flex-direction: column;
  }

  .price {
    /* display: flex; */
    /* flex-direction: column; */
    gap: 16px;
    align-items: center;
    margin-top: 15px;

  }

  .rate {
    font-size: 80%;
    color: rgba(128, 128, 128, 0.774);
  }

  .disratre {
    font-size: 130%;
    font-weight: 650;
    color: #ff7c08;
  }

  .discount {
    font-size: 100%;
    font-weight: 500;
    margin-top: 10px;
    color: rgb(234, 218, 35);
    /* margin-bottom: -90px; */
  }

  .desc {
    margin-top: 1%;
  }

  .line-after-card{
    margin: 0;
    width: 100vw;
    margin-top: -25vh;
  }
  .button-2 {
    display: block;
    /* background-color: #ff7c08; */
    /* hover: #9b2c2c; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: #ffffff;
    border-radius: 0.375rem;
    margin-top: 0;
    /* margin-bottom: 3rem; */
    /* display: none; */
  }
}
