.offer_item{
    background-color: #EEF6EB;
    padding: 100px 0px;
}

.section_heading h4 {
    color: var(--colorPrimary);
    font-family: var(--cursiveFont);
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
}

.section_heading h4::after {
    position: absolute;
    content: "";
    background: url(../assets/heading_shapes_1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 65px;
    height: 23px;
    top: 3px;
    right: -80px;
}

.section_heading h2 {
    text-transform: capitalize;
    font-size: 35px;
    font-weight: 800;
}

.offer_item_single {
    /* background-image: url(../assets/daalmakhani.jpg); */
    /* background-position: center !important; */
    /* background-repeat: no-repeat !important; */
    /* background-size: cover !important; */
    /* padding: 25px 20px 25px 195px; */
    border-radius: 5px;
    border: 10px solid var(--colorWhite);
    margin: 25px 0px 0px 0px;
    width: 90%;
    display: flex;
    /* background-color: white; */
    /* position: relative; */
}

.offer_item_single_content,.offer_item_single_content1{
    width: 50%;
    padding: 10px;
}

.offer_item_single_content1{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dailyofferimage{
    width: 100%;
    height: 150px;
    border-radius: 10px;
}

.dailyofferrow{
    display: flex;
}

.dailyoffercolumn{
    width: 100%;
}

.offer_item_single span {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    top: 35px;
    left: 115px;
    width: 60px;
    height: 60px;
    line-height: 18px;
    background: var(--colorPrimary);
    border: 2px solid var(--colorWhite);
    border-radius: 50%;
    color: var(--colorWhite);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.offer_item_single .title {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0px 5px;
    transition: all linear .3s;
    display: block;
    color: var(--colorBlack);
    font-family: var(--headingFont);
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
}

.offer_item_single p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



.dailyofferul{
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 15px; */
    /* gap: 15px; */
    justify-content: space-between;
}

.dailyofferuldiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.offer_item_single ul {
    display: flex;
    line-height: 30px;
}

.dailyofferbtndiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.offer_item_single ul li a {
    width: 30px;
    height: 30px;
    background: var(--colorPrimary);
    text-align: center;
    margin-right: 5px;
    line-height: 30px;
    color: var(--colorWhite);
    font-size: 14px;
    border-radius: 50%;
    transition: all linear .3s;
    padding: 8px 10px;
}

.dailyofferbtn{
    /* width: 30px; */
    /* height: 30px; */
    background: var(--colorPrimary);
    text-align: center;
    margin-right: 5px;
    /* line-height: 30px; */
    color: var(--colorWhite);
    font-size: 12px;
    font-weight: 600;
    border-radius: 10px;
    transition: all linear .3s;
    padding: 10px 15px; 
}
.offer_item_single:hover .title {
    color: var(--colorPrimary);
}

.offer_item_single ul li a:hover {
    background: var(--colorBlack);
}


@media screen and (max-width:600px) {
    .container{
        padding-left: 0 !important;
    }

    .dailyofferrow{
        flex-direction: column;
    }

    .offer_item_single{
        width: 95%;
        margin-left: 10px;
        /* padding: 20px 5px 20px 145px; */
    }
    .offer_item_single span{
        left: 80px;
        top: 40px;
    }

    .dailyofferul{
        flex-direction: column;
    }

    .dailyofferbtndiv{
        /* display: flex;
        align-items: center;
        justify-content: center; */
        margin-top: 10px;
        margin-left: 5px;
    }
}