:root {
    --colorPrimary: #ff7c08;
    --colorRed: #eb0029;
    --paraColor: #484747;
    --colorBlack: #231f40;
    --colorWhite: #ffffff;
    --paraFont: 'Manrope', sans-serif;
    --headingFont: 'Barlow', sans-serif;
    --cursiveFont: 'Oleo Script', cursive;
    --boxShadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
}

.bookatablediv{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
}

.bookatablerow{
 width: 80%;
 /* height: 80vh; */
 position: relative;
 display: flex;
 border-radius: 0px 10px 10px 0px;
 background: url(../assets/reservation_bg.jpg);
}

.bookatablerow::before {
    position:absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: -1px;
    background: #ff7c08e8;
    clip-path: polygon(0 0, 54% 0, 75% 100%, 0 100%);
    border-radius: 0px 10px 10px 0px;
    -webkit-border-radius: 0px 10px 10px 0px;
    -moz-border-radius: 0px 10px 10px 0px;
    -ms-border-radius: 0px 10px 10px 0px;
    -o-border-radius: 0px 10px 10px 0px;
}

.bookatablecolumndiv{
    display: flex;
    padding: 50px 0 50px 50px;
    width: 50%;
}

.bookatableheading{
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 600;
    color: var(--colorWhite);
    border-bottom: 1px solid var(--colorWhite);
    padding-bottom: 6px;
    margin-bottom: 25px;
}
.bookatablecolumn{
    /* position: relative; */
    z-index: 1;
    flex: 1;
    margin-left: 1rem;
    width: 100%;
}

.bookatablecolumninner{
    display: flex;
}

.bookatableinputdivlabel{
    text-transform: capitalize;
    color: var(--colorWhite);
    font-weight: 600;
    margin-bottom: 5px;
}
.bookatableinputdivinput{
    width: 100%;
    padding: 12px 20px;
    outline: none;
    resize: none;
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    -webkit-border-radius: 4px;
}

.bookatablecolumninner{
    margin-bottom: 20px;
}

.bookatableinputdiv{
    width: 100%;
    margin: 0px 20px 0px 10px;
}

.bookatablecolumninner .common_btn{
    width: 100%;
    border-radius: 4px;
    background-color: #231f40;
    margin-top: 5px;
    -webkit-border-radius: 4px;
    text-transform: capitalize;
    color: var(--colorWhite);
    padding: 12px 40px 12px 40px;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
}

@media screen and (max-width:600px) {
    .bookatablecolumndiv{
        padding: 10px;
        width: 100%;
    }

    .bookatablecolumn{
        margin: 0;
    }

    .bookatablerow{
        width: 100%;
    }

    .bookatableinputdivdateinput{
        padding: 8px;
    }

    .bookatableinputdiv1{
        margin: 0 5px;
    }
}
