.homepagediv::before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--colorBlack);
        opacity: 0.5;
        z-index: -1;
}