.downloadrow{
    display: flex;
}

.downloadcolumn1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
}

.downloadcolumn2{
    padding-top: 50px;
    width: 60%;
}

.download_text_bg {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    overflow: hidden;
    height: 450px;
    background-image: url(../assets/download_img.png);
    margin: 50px 0px;
}

.download_text_overlay {
    background: #231f40cc;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.download_text h5 {
    color: var(--colorPrimary);
    text-align: center;
    font-size: 25px;
    font-family: var(--cursiveFont);
}

.download_text h2 {
    text-align: center;
    color: var(--colorWhite);
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 25px;
}

.download_text ul {
    justify-content: center;
}

.download_text ul li a {
    background: var(--colorPrimary);
    padding: 8px 25px;
    border-radius: 5px;
    display: flex;
    margin: 15px 5px;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.download_text ul li a .icon {
    font-size: 35px;
    color: var(--colorWhite);
    margin-right: 15px;
}

.download_text ul li a p {
    font-size: 20px;
    color: var(--colorWhite);
    font-weight: 700;
}

.download_text ul li a p span {
    display: block;
    color: var(--colorWhite);
    font-size: 14px;
    line-height: 20px;
}

.download_text ul li a:hover {
    background: var(--colorRed);
}

.download_slider {
    height: 450px;
    margin: 0px 12px;
}

.imgfluid{
    height: 450px;
}


@media screen and (max-width:600px) {
    .downloadrow{
        flex-direction: column;
    }

    .downloadcolumn1,.downloadcolumn2{
        width: 100%;
    }

    .download_text h2{
        font-size: 30px;
    }

}