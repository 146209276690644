.explorecardimage{
    width: 130px;
    height: 130px;
}

.explorecardpara{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}