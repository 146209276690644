.rans {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  gap: 10vw;
  margin-top: 2%;
  margin-bottom: -20px;
}
.child-sortbox {
  width: 20%;
  display: flex;
  justify-content: center;
  margin-left: 2%;
  gap: 20%;
}
.noncollor {
  font-size: 30px;
}
.collor {
  font-size: 25px;
  font-weight: 600;
  margin-top: 10px;
  color: #ff7c08;
}

@media (max-width: 600px) {
  .child-sortbox {
    display: none;
  }
}

.child-card {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4%;
  margin-left: -80px;
  /* gap: 10%; */
}

.child-card h1 {
  font-size: 3vw;
}

.toggle {
  display: flex;
  width: 20%;
}
.toggle span {
  font-size: 130%;
  margin-right: 10px;
}

.sortb {
  border: 1px solid black;
  border-radius: 2px;
  padding: 5px;
}

@media (max-width: 600px) {
  .child-card {
    width: 100%;
    margin-left: 1%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 10%;
  }
  .child-card h1 {
    margin-left: 6%;
    font-size: 6vw;
  }
  .toggle {
    margin-top: 2%;
    margin-left: 5%;
    display: flex;
    width: 60%;
  }
  .toggle span {
    font-size: 90%;
    padding: 2%;
  }
  .sortb {
    width: 25%;
    border: 1px solid black;
    border-radius: 2px;
    /* padding: -5px; */
  }
  .sortb select{
    width: 90%;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff7c08;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff7c08;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
