.easection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.exploreparadiv{
    padding: 30px;
}
.exploreparadiv h3{
font-size: 25px;
font-weight: 700;
}

.exploreparadiv p{
    margin-top: 10px;
    font-size: 20px;
}

.activityname{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.exploreimage{
    height: 300px;
    width: 100%;
}

.explorebtndiv{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px;
    margin-bottom: 20px;
}

.explorebtndiv > button{
    background-color: black;
    color: white;
    padding: 10px 20px;
}

@media screen and (max-width:600px) {
    
}