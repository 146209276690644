:root {
    --colorPrimary: #ff7c08;
    --colorRed: #eb0029;
    --paraColor: #484747;
    --colorBlack: #231f40;
    --colorWhite: #ffffff;
    --paraFont: 'Manrope', sans-serif;
    --headingFont: 'Barlow', sans-serif;
    --cursiveFont: 'Oleo Script', cursive;
    --boxShadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
}

.topbar{
    width: 100%;
    height: 50px;
    background: var(--colorBlack);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topbarrow{
    width: 80%;
}

.topbar_info{
    line-height: 50px;
    position: relative;
    justify-content: center;
    z-index: 1;
    font-weight: 700;
}

.topbar_info ul li a{
    color: var(--colorBlack) !important;
}

.topbar_icon {
    line-height: 50px;
    position: relative;
    z-index: 1;
    font-weight: 700;
    display: flex;
   justify-content: flex-end;
}

.topbar_info::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--colorPrimary);
    top: 0;
    left: 0;
    z-index: -1;
    height: 50px;
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

.topbar_icon li a {
    color: var(--colorWhite);
    width: 30px;
    height: 25px;
    line-height: 20px;
    border-radius: 50%;
    background: var(--colorPrimary);
    text-align: center;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    transition: all linear .3s;
    position: relative;
    padding: 8px 10px;
    color: white;
    /* margin: 8px; */
}

.topbar_icon li a:hover {
    background: var(--colorWhite);
    color: var(--colorPrimary);
}
.menu_icon{
    display: flex;
}

.menu_icon li a {
    font-size: 16px;
    font-weight: 600;
    color: var(--colorBlack);
    text-transform: capitalize;
    margin: 0px 10px;
    padding: 0;
    position: relative;
    cursor: pointer;
    transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -moz-transition: all linear .3s;
    -ms-transition: all linear .3s;
    -o-transition: all linear .3s;
    width: 40px;
    height: 40px;
    background: #ff7c0826;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    padding: 8px 10px;
}

.menu_icon li a span {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--colorPrimary);
    color: var(--colorWhite);
    border: 1px solid var(--colorWhite);
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    top: -10px;
    right: -10px;
}

@media screen and (max-width:600px) {
    .topbar{
        display: none;
    }
    .menu_icon {
        display: none;
    }
}