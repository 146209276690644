/* add slider start */

.add_slider{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.addsliderrow{
    display: flex;
    width: 90%;
    /* justify-content: space-between; */
    gap: 50px;
}

.teamnextcolumn1{
    background-image: url(../assets/travel-prasadam.jpg);
    color: white;
    position: relative;
    z-index: 1;
}

.teamnextcolumn2{
    background-image: url(../assets/jagganath-aabha.jpg);
    color: white;
    position: relative;
    z-index: 1;
}

.teamnextcolumn1::after,.teamnextcolumn2::after{
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* height: 83vh; */
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}


.add_slider_single {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-radius: 10px;
    padding: 40px;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin:0 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.add_slider_single .text {
    width: 50%;
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
}

.add_slider_single h5 {
    font-family: var(--cursiveFont);
    color: var(--colorPrimary);
    text-transform: capitalize;
    font-size: 20px;
}

.add_slider_single h2 {
    color: white;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: capitalize;
    margin-top: 10px;
}

.add_slider_single p{
    margin-bottom: 30px;
}

.add_slider_single button {
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 700;
    color: var(--colorBlack);
    padding-bottom: 0px;
    transition: all linear .3s;
    text-decoration: none;
    padding: 10px;
    background-color: #ff7c08;
    border-radius: 10px;
    color: white;
}

.add_slider_single a i {
    margin-left: 5px;
}

.add_slider_single a:hover {
    color: var(--colorPrimary);
    border-color: var(--colorPrimary);
}

.travelprasadam{

}
@media screen and (max-width:600px) {
    .addsliderrow{
        flex-direction: column;
        gap: 20px;
    }
    .add_slider_single{
        margin: 0;
        padding:20px;
        margin-bottom: 20px;
    }
    .add_slider{
        padding: 15px;
    }
    .add_slider_single .text {
        width: 100%;
    }
}

